.homepage-wrapper {
    height: 100vh;
    position: relative;
    &.1 {
        &::after {
            background-image: url(../assets/casey-lee-awj7sRviVXo-unsplash.jpg);
        }
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        background: linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.8),
            rgba(0, 0, 0, 0.8)
        );
        width: 100%;
        height: 100%;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: url(../assets/brooke-lark-4J059aGa5s4-unsplash.jpg);
        z-index: -2;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }
    &.one-1 {
        &::after {
            background-image: url(../assets/casey-lee-awj7sRviVXo-unsplash.jpg);
        }
    }
    &.one-2 {
        &::after {
            background-image: url(../assets/chad-montano-eeqbbemH9-c-unsplash.jpg);
        }
    }
    &.one-3 {
        &::after {
            background-image: url(../assets/edgar-castrejon-1SPu0KT-Ejg-unsplash.jpg);
        }
    }
    &.one-4 {
        &::after {
            background-image: url(../assets/joseph-gonzalez-fdlZBWIP0aM-unsplash.jpg);
        }
    }
    .logo {
        background-image: url(https://www.tajsats.com/public/front_assets/images/logo.png);
        width: 8rem;
        height: 4rem;
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        margin: 0 auto 0;
        position: absolute;
        top: 2rem;
        left: 50%;
        transform: translateX(-50%);
    }
    .hero-image {
        display: none;

        .shape-designs {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            padding: 2rem 0;
            overflow: hidden;
            filter: opacity(0.25);
            .shape {
                width: 13rem;
                height: 13rem;
                background-color: #e2ddfe;
                border-radius: 50%;
                margin: 0.5rem 0;
                background-image: url(../assets/cupcake.png);
                background-size: 60%;
                background-repeat: no-repeat;
                background-position: center center;

                &::before {
                    content: '';
                    position: absolute;
                    width: 13rem;
                    height: 13rem;
                    background-color: #e2ddfe;
                    border-radius: 50%;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 13rem;
                    height: 13rem;
                    background-color: #e2ddfe;
                    border-radius: 50%;
                }
                &.shape-1 {
                    &::before {
                        top: 0;
                        left: -4rem;
                        width: 8rem;
                        height: 8rem;
                        background-color: #e6effe;
                        background-image: url(../assets/drink.png);
                        background-size: 60%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    &::after {
                        content: '';
                        top: 1rem;
                        right: -5rem;
                        width: 9rem;
                        height: 9rem;
                        background-color: #f2f2f2;
                        background-image: url(../assets/hot.png);
                        background-size: 60%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
                &.shape-2 {
                    width: 9rem;
                    height: 9rem;
                    margin-left: -4rem;
                    background-color: #fbe9fb;
                    background-image: url(../assets/ramen.png);
                    background-size: 60%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    margin-top: 1.5rem;
                    &::before {
                        bottom: 6rem;
                        left: -7rem;
                        width: 13rem;
                        height: 13rem;
                        background-color: #f2f2f2;
                        background-image: url(../assets/rasgulla.png);
                        background-size: 60%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                    &::after {
                        content: '';
                        bottom: 3rem;
                        right: -5rem;
                        width: 13rem;
                        height: 13rem;
                        background-color: #e6effe;
                        background-image: url(../assets/rasgulla1.png);
                        background-size: 60%;
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
            }
        }
    }
    .get-started {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 0;
        left: 1rem;
        right: 1rem;
        box-shadow: rgba(0, 0, 0, 0.13) 0px 3px 25px -3px;
        padding: 1.5rem;
        border-radius: 1.52rem 1.52rem 0 0;
        background-color: #fff;
        .title {
            font-size: 1.25rem;
            color: #2e3d62;
            margin-bottom: 0.75rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            span {
                color: #a2a2a2;
            }
        }
        .subtitle {
            color: #a2a2a2;
            font-weight: 400;
            font-size: 0.85rem;
            margin-bottom: 1rem;
        }
        .forminput-wrapper {
            margin-bottom: 1rem;
        }
    }
    .mealtype-choices {
        display: flex;
        flex-direction: column;
        position: fixed;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        gap: 1rem;
        // box-shadow: rgba(0, 0, 0, 0.13) 0px 3px 25px -3px;
        // padding: 1.5rem;
        // border-radius: 1.52rem 1.52rem 0 0;
        // background-color: #fff;
        .title {
            font-size: 1.25rem;
            color: #fff;
            // text-shadow: 0px 1px 1px #fff;
            font-weight: 300;
            letter-spacing: 0.25px;
            line-height: 1.2;
        }
        .mealtype-card {
            display: flex;
            padding: 0.75rem;
            border-radius: 1rem;
            background-color: #fff;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            align-items: flex-start;
            gap: 1rem;
            background-image: url('../assets/corner.png');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right;
            // border: 1px dashed #656565;
            position: relative;
            .icon {
                min-width: 5rem;
                height: 5rem;
                background-image: url('../assets/eye.png');
                object-fit: contain;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50%;
                background-color: #ffece6;
                border-radius: 1rem;
            }
            .meal-type-title {
                font-size: 1rem;
                margin-bottom: 0.25rem;
                font-weight: 500;
            }
            .meal-type-desp {
                font-size: 0.75rem;
                font-weight: 400;
            }
            &.transactional {
                background-image: url('../assets/corner-2.png');
                .icon {
                    background-image: url('../assets/online-payment.png');

                    background-color: #d8efff;
                }
            }
            .arrow-icon {
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtElEQVR4nO3YPQrCQBRF4VPpfiytLSyMC1BSGLNVK7GwcQFWgrV/hUYCU4hoIYGY+7gfvAUcZpiZBMzMzMzMOmgIbNKMELYDqjQ3IEPU/iVEOmYG3D/ETBC0+BIzRZBj1FYmQ5BjfpEDx7elb3MuwJiGesD5jxFVmmvTe6YPnCKE1ObAQX1rtamI8HQpItwlS0d0RBnhBVxGiMiBh/rpFOpTdxshojYAVsBa/XeQmZmZmaHlCeC06ncEGe4qAAAAAElFTkSuQmCC');
                width: 50px;
                height: 50px;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50%;
                position: absolute;
                bottom: 0;
                right: 0;
                animation: example 1s linear infinite alternate;
            }
        }
    }
}

@keyframes example {
    from {
        transform: translateX(-5px);
    }
    to {
        transform: translateX(5px);
    }
}
