.item-food-card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0.75rem;
    &:last-child {
        margin-bottom: 0;
    }
    .item-type {
        margin-right: 5px;
        img {
            width: 16px;
            height: 16px;
            object-fit: contain;
            margin-top: 3px;
        }
    }
    .item-details-wrapper {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        width: 100%;
        box-shadow: 0 0px 7px #c9c9c97d;
        border: 1px solid #e3ebf6;
        border-radius: 8px;
        overflow: hidden;
        padding: 0.4rem;
        background-color: #fff;
        border-radius: 14px;
        padding-bottom: 0.75rem;
        align-items: flex-start;
        .item-thumbnail {
            position: relative;
        }
        .item-type {
            position: static;
            left: 0;
            bottom: 0;
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            top: 0;
            right: 0;
        }
        &.big_thumbnail {
            flex-direction: column;
            padding: 0rem;
            .item-title-wrapper {
                padding: 0.5rem;
            }
            .item-image {
                display: flex;
                width: 100%;
                height: 180px;
                object-fit: cover;
            }
            .icon-img {
                position: absolute;
                left: 15px;
                bottom: 15px;
                width: 20px;
                height: 20px;
                border-radius: 3px;
                top: auto;
                right: auto;
                background-color: #fff;
            }
        }
        &.small_thumbnail {
            .item-title-wrapper {
                padding: 0 0 0rem;
            }
            .item-image {
                display: flex;
                object-fit: cover;
                min-width: 70px;
                height: 70px;
                max-width: 70px;
                border-radius: 8px !important;
                box-shadow: 0 0 1px 0 #4343434d;
            }
            .icon-img {
                // position: absolute;
                // left: auto;
                // bottom: auto;
                width: 15px;
                height: 15px;
                min-width: 15px;
                min-height: 15px;
                border-radius: 3px;
                right: 8px;
                bottom: 8px;
                background-color: #fff;
            }
        }
        &.no_thumbnail {
            .item-image {
                display: none;
            }
        }
        &.out-of-stock {
            opacity: 0.35;
            .btn-update-item {
                background-color: #f00;
            }
        }
    }
    .item-title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;

        .item-title {
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            margin: 0px;
            white-space: pre-wrap;
        }
        .item-price {
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            margin: 0px;
            white-space: nowrap;
        }
    }
    .item-actions {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
    }

    .item-description {
        max-width: 100%;
        margin: 0px;
        line-height: 15px;
        white-space: pre-wrap;
        font-size: 0.6rem;
        font-weight: 400;
        color: #2e3d62;
    }

    .customizable {
        line-height: 1rem;
        display: inherit;
        font-size: 0.65rem;
        margin-top: 1px;
        color: rgba(244, 162, 102, 1);
        text-align: center;
        margin: 0;
    }
    .food-alergy {
        margin: 0.25rem 0;
        .alergy-icon {
            width: 15px;
            height: 15px;
            filter: grayscale(1);
            margin: 0 2px;
        }
    }
}

.fixedLayout {
    background-color: #fcfcf7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding: 0 20px;
    padding-bottom: 100px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding-top: 3rem;
    color: #1c1c1c;
    .arrowBack {
        width: 36px;
        margin-bottom: 12px;
    }
    .banner {
        width: auto;
        height: 144px;
        border-radius: 8px;
    }
    &.bg-image {
        background-image: url('https://master-vserve.s3.ap-south-1.amazonaws.com/hotels/menu_bg_image_upload.jpg');
        background-position: 0 0;
        background-size: contain;
        color: #fff;
    }
}
.cat-banner-image {
    height: 200px;
    border-radius: 8px;
}

.btn-add-item {
    align-items: center;
    background: #1d1e22;
    border: 1px solid #b59a63;
    border-radius: 6px;
    color: #b59a63;
    display: flex;
    font-size: 10px;
    font-weight: 500;
    height: 22px;
    justify-content: center;
    padding: 3px 10px;
    border-radius: 10rem;
    span {
        margin-right: 4px;
    }
}
.counter {
    // display: flex;
    // justify-content: space-between;
    // border: 1px solid #2e3d62;
    // border-radius: 0.5rem;
    // overflow: hidden;
    // cursor: pointer;
    // user-select: none;
    // padding: 0;
    // border-radius: 6px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    -o-border-image: initial;
    border-image: initial;
    border-radius: 22px;
    align-items: center;
    font-size: 11px;
    width: 62px;
    border: 1px solid #2e3d62;
    font-weight: 500;
    padding: 1px 3px;
    background-color: #fff;
    .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        outline: none;
        background-color: #2e3d62;
        color: #b59a63;
        cursor: pointer;
        padding: 4px;
        margin-left: -2px;
        border-radius: 50%;
        font-size: 10px;
        width: 20px;
        height: 20px;
        max-width: 20px;
        &:last-child {
            margin-right: -2px;
            margin-left: unset;
        }

        &.count {
            background: transparent;
            color: #2e3d62;
            font-size: 14px;
            font-weight: 500;
            pointer-events: none;
        }
    }
}
