.view-only-container {
    min-height: 100vh;
    // background: #05070e;
    // background: #fcfcf7;
    background: #FFFAF6;
    color: #fff;
    // padding: 1rem;
    .back-btn {
        width: 30px;
        height: 30px;
        background: #fff;
        background: #05070e;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        border-radius: 50%;
        font-size: 0.8rem;
        box-shadow: 0px 0px 6px #fff;
        position: absolute;
        top: 0.75rem;
        left: 1rem;
    }
    .menu-title {
        font-size: 2rem;
        text-align: center;
        margin-top: 1rem;
        text-transform: uppercase;
        font-weight: 100;
        letter-spacing: 1.5px;
        color: #05070e;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1.25rem;
        line-height: 1.2;
    }
    .menu-content {
        // margin-top: 4rem;
        // background-image: url('../assets/frame.png');
        background-size: 100% 100%;
        background-position: center center;
        .menu-content-container {
            width: 85%;
            display: block;
            margin: auto;
            padding-bottom: 2rem;
            padding-top: 5rem;
        }
        .view-only-category {
            color: #ac7b66;
            font-size: 0.85rem;
            font-weight: 500;
            letter-spacing: 0.5px;
            margin: 1.5rem 0;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            .count {
                border: 1px solid #ac7b66;
                width: 18px;
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 4px;
                border-radius: 22px;
                color: #2e3d62;
                font-size: 10px;
            }
        }
        .view-only-item-wrapper {
            // padding-left: 1.5rem;
            position: relative;
            // border-bottom: 1px solid #ac7b66;
            margin-bottom: 1.25rem;
            .name-wrapper {
                display: flex;
                position: relative;
                align-items: flex-end;
                gap: 5px;
                .name {
                    color: #05070e;
                    font-size: 0.75rem;
                    text-transform: lowercase;
                    white-space: nowrap;
                    text-transform: uppercase;
                    font-weight: 500;
                }
                .line {
                    width: 100%;
                    height: 4px;
                    border-top: 1px dashed #939393;
                }
            }
            .description {
                font-size: 0.55rem;
                color: #9d9d9d;
                margin: 0;
                margin: 5px 0 10px 0;
                p {
                    margin: 0;
                }
            }
            .food-alergy {
                margin: 4px 0;
                .alergy-icon {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
    .days_wrapper {
        background: #fff;
        margin: 0;
        padding: 0;
        border: 1px solid #d3d3d3;
        position: relative;
        .day {
            &::before {
                background: inherit;
            }
        }
    }
}
