.review-order-wraper {
    padding: 0.5rem 0.75rem 5rem;
    .page-title {
        margin-bottom: 0.5rem;
    }
}

.revieworder-card {
    display: flex;
    background: #fff;
    box-shadow: 0 0 2px #c9c9c9;
    color: #2e3d62;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    flex-direction: row;
    margin-bottom: 1rem;
    .img-wrapper {
        width: 8rem;
        height: 8rem;
        background-image: url('../assets/indian.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        min-width: 8rem;
    }
}

.details-wrapper {
    padding: 0.25rem 0.5rem;
    width: 100%;
    .your-customization {
        padding-left: 1rem;
    }
    .item-title {
        font-size: 0.9rem;
        font-weight: 500;
        color: #2e3d62;
        margin-bottom: 0.5rem;
    }
    .addon-name {
        font-size: 0.75rem;
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.25rem;
    }
}
.price-details {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    background-color: #fcfcf7;
    border: 1px solid #ececec;
    // box-shadow: 0 0 1px #c9c9c9;

    .price__item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.75rem;
        color: #1c1c1c;
        .label,
        .val {
            font-size: 0.85rem;
        }
        &.total {
            color: #1c1c1c;
            font-weight: 500;
            border-bottom: 1px solid #e8e8e8;
            padding-bottom: 0.5rem;
        }
        &.tax {
            .label,
            .val {
                font-size: 0.75rem;
            }
        }
        &.grand-pay {
            font-weight: 600;
            border-top: 1px dashed #071b41;
            padding-top: 0.5rem;
            color: #042055;
            margin-bottom: 0;
        }
    }
}
.add-details {
    margin: 0.5rem 0;
    .title {
        font-size: 0.85rem;
        font-weight: 500;
        color: #2e3d62;
        margin-bottom: 0.75rem;
    }
    .form-input {
        margin-bottom: 1rem;
    }
}
.add-morebtn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}
.total-amt {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    margin: 0px;
    white-space: nowrap;
    span {
        font-size: 16px;
        font-weight: 600;
    }
}
