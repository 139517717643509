.page-title {
    font-size: 0.9rem;
    font-weight: 300;
    color: #2e3d62;
    margin-bottom: 1.25rem;
}
.days_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    border-bottom: 1px solid #d2dcde;
    margin: 0;
    width: 100%;
    position: sticky;
    top: 55px;
    background: #fff;
    z-index: 99;
    .day {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #adadad;
        font-size: 13px;
        padding: 0.8rem 7px;
        width: 100%;
        .date {
            font-size: 13px;
            margin-top: 8px;
        }
        &.active {
            color: #151a21;
            background: #b59a63;
            .date {
                font-weight: 500;
            }
            &::before {
                position: absolute;
                content: '';
                position: absolute;
                left: 0;
                bottom: -1px;
                width: 100%;
                height: 2px;
                background: #151a21;
            }
        }
    }
}

.days-wrapper {
    padding: 1rem;

    .days-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        gap: 1rem;
        .day {
            background: #fff;
            box-shadow: rgba(0, 0, 0, 0.13) 0px 3px 25px -3px;
            color: #2e3d62;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            width: calc(100% / 2 - 0.5rem);
            border-radius: 1rem;
            background-image: url('../assets/continental.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            height: 10rem;
            position: relative;
            overflow: hidden;
            .day-title {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                background: #fff;
                padding: 13px;
                text-align: center;
                font-weight: 400;
                color: #2e3d62;
                font-size: 13px;
            }
        }
    }
}
.item-list-container {
    padding: 0 0.75rem;
}
.menuitem-listing-wrapper {
    padding: 0;
    padding-bottom: 4rem;
    .item-categories {
        margin: 0.5rem 0;
    }
    .category-name {
        background-color: #b59a63;
        color: #ffffff;
        padding: 4px;
        width: fit-content;
        min-width: 30%;
        position: relative;
        min-height: 30px;
        margin-bottom: 1rem;
        padding-left: 1rem;
        display: flex;
        align-items: center;
        font-size: 13px;
        &::after {
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            width: 0px;
            border-bottom: 15px solid transparent;
            border-top: 15px solid rgba(181, 154, 99, 1);
            border-left: 15px solid rgba(181, 154, 99, 1);
            border-right: 15px solid transparent;
            right: -30px;
        }
        &::before {
            content: '';
            position: absolute;
            top: 0px;
            right: 0px;
            width: 0px;
            border-bottom: 15px solid rgba(181, 154, 99, 1);
            border-top: 15px solid transparent;
            border-left: 15px solid rgba(181, 154, 99, 1);
            border-right: 15px solid transparent;
            right: -30px;
        }
    }
}
.food-alergy {
    margin: 1rem 0;
    .alergy-icon {
        width: 20px;
        height: 20px;
        filter: grayscale(1);
        margin: 0 3px;
    }
}
.floatbtn {
    position: fixed;
    bottom: 5rem;
    right: 1rem;
}
.mealcard {
    background: #fff;
    // border: 1px solid #e3ebf6;
    box-shadow: 0 0 2px #c9c9c9;
    color: #2e3d62;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    .meal-img {
        background-image: url('../assets/indian.png');
        width: 100%;
        height: 13rem;
        background-size: cover;
        background-position: center center;
    }
    .meal-details {
        padding: 0.5rem;
        background-color: #FFFAF6;
        .meal-title {
            font-size: 0.9rem;
            font-weight: 400;
            color: #2e3d62;
            margin-bottom: 0.5rem;
        }
        .meal-description {
            font-size: 0.75rem;
            font-weight: 400;
            color: #2e3d62;
            margin-bottom: 1rem;
        }
    }
}

.review-wrapper {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    background: #fff;
    width: 100%;
    padding: 0.75rem;
    box-shadow: 0 0px 7px #c9c9c97d;
    align-items: center;
}

.time-tabs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #fff;
    border-radius: 22px;
    color: #fff;
    margin: 0 1rem;
    gap: 5px;
    padding: 2px;
    box-shadow: 0 0px 7px #c9c9c97d;
    border: 1px solid #e3ebf6;
    .tab {
        padding: 9px;
        color: #2e3d62;
        border-radius: 22px;
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        transition: 0.25s ease-out;
        &.active {
            background: #f5f3fd;
            font-weight: 500;
        }
    }
}
.categories-container {
    padding: 0.5rem 0 1rem;
    background: #fcfcf7;
  
        .cat-title {
            font-size: 0.9rem;
            font-weight: 400;
            color: #2e3d62;
            padding-left: 1rem;
        }
        .categories-wrapper {
            display: flex;
            flex-wrap: nowrap;
            gap: 0.5rem;
            overflow-x: auto;
            padding: 0.75rem 1rem;
            align-items: flex-start;
            .category-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-width: 4.5rem;
            .icon-cat {
                width: 35px;
                height: 35px;
                object-fit: cover;
                background: #fff;
                box-shadow: 0px 0px 3px rgba(46, 61, 98,1);
                border-radius: 22px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 60%;
                    height: 60%;
                    object-fit: contain;
                }
            }
            .cat-name {
                font-size: 11px;
                line-height: 150%;
                margin: 0px;
                white-space: pre-wrap;
                margin-top: 5px;
                text-align: center;
            }
        }
    }
}
