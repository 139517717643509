.mealpref-wapper {
    padding: 1rem;
    background: #f6f8ff;
    background: #FFFAF6;
    min-height: calc(100vh - 56px);
    .menu-type-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        .menu-type {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 220px;
            object-fit: cover;
            background-position: center center;
            background-size: cover;
            margin-bottom: 1.5rem;
            box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
            border-radius: 15px;
            cursor: pointer;
            overflow: hidden;
            background-color: #ffffff;
            color: #2e3d62;
            padding: 1rem;
            .icon {
                width: 2rem;
                height: 2rem;
                background-image: url('../assets/online-payment.png');
                object-fit: contain;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                margin-bottom: 1rem;
            }
            .menu-type-title {
                font-size: 1.25rem;
                margin-bottom: 1rem;
                font-weight: 400;
            }
            .menu-type-desp {
                font-size: 0.75rem;
                font-weight: 400;
            }
            &.viewonly {
                .icon {
                    background-image: url('../assets/eye.png');
                }
            }
        }
    }
    .mealpref-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }
    .page-title {
        margin-bottom: 1rem;
        font-size: 0.9rem;
        font-weight: 300;
        color: #2e3d62;
        text-align: center;
    }
    .subtitle {
        font-size: 1.25rem;
        color: #2e3d62;
        margin-bottom: 1rem;
        font-weight: 500;
        span {
            color: #a2a2a2;
        }
    }

    .mealkit-container {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        height: 220px;
        object-fit: cover;
        background-position: center center;
        background-size: cover;
        margin-bottom: 1rem;
        box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
        border-radius: 15px;
        cursor: pointer;
        overflow: hidden;
        &:last-child {
            margin-bottom: 0;
        }
        .mealkit-name {
            position: absolute;
            left: 10px;
            top: 50%;
            font-weight: 400;
            color: #b59a63;
            font-family: 'Sacramento', cursive;
            font-size: 2.5rem;
            line-height: 1.2;
            transform: translateY(-50%);
        }
        &.indian {
            background-image: url(../assets/indian.png);
        }
        &.continental {
            background-image: url(../assets/continental.png);
            .mealkit-name {
                right: 10px;
                left: unset;
                font-size: 2rem;
                text-align: right;
            }
        }
        &.oriental {
            background-image: url(../assets/oriental.png);
        }
    }
}

.options-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 1.5rem;
    .payment-option {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        box-shadow: 0 0px 7px #c9c9c97d;
        border: 1px solid #e3ebf6;
        border-radius: 8px;
        overflow: hidden;
        padding: 0.4rem;
        background-color: #fff;
        border-radius: 14px;
        .payment-option-img {
            width: 100%;
            margin: 1rem 0;
            min-height: 2rem;
            object-fit: contain;
            max-width: 5rem;
            max-height: 5rem;
            object-fit: contain;
        }
        .option-title {
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            margin: 0px;
        }
    }
}
