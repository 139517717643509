.header {
    position: relative;
    z-index: 10;
    max-width: 100%;
    background: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
        0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
    padding: 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    .back-icon {
    }
    .header-logo {
        background-image: url(https://www.tajsats.com/public/front_assets/images/logo.png);
        width: 3rem;
        height: 1.5rem;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .info-icon {
    }
}
